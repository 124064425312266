'use strict';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import { calculateFinalPrice } from '../src/utils/prices/calculateFinalPrice';

const request = require('../Request');
const Constants = require('../Constants');
const utils = require('../Utils');
const validations = require('../Validations');

class LicenseModel {
  constructor() {
    /* ---------- */
    /*   Client   */
    /* ---------- */
    this.activateCallback = null; // Callback to execute when the license activation modal is closed.
    this.activating = false; // Whether the client is currently activating a license.
    this.cardType = ''; // The type of credit card icon displayed during license purchase (see the types in Constants.js).
    this.couponSuccess = false; // Whether the client has successfully looked up a valid coupon by name.
    this.errors = {
      // Collection of license input errors. Set errors/messages with the setError() helper method.
      couponName: { error: false, message: '' },
      nameOnCard: { error: false, message: '' },
      cardNumber: { error: false, message: '' },
      month: { error: false, message: '' },
      year: { error: false, message: '' },
      cvc: { error: false, message: '' },
      licenseKey: { error: false, message: '' },
    };
    this.fetchingCoupon = false; // Whether the client is currently looking up a coupon by name.
    this.licenseStep = Constants.LICENSE_STEP; // Which license step the client is rendering at the moment.
    this.makingPurchase = false; // Whether the client is currently making a purchase.
    this.nameOnCard = ''; // The name on the credit card entered by the buyer.
    this.pageBlurred = false; // Whether the page has been blurred after opening the license activation modal.
    this.pricePerSeatFull = 0; // The price per seat before the coupon discount.
    this.renewalDate = new Date(); // The date displayed to the buyer when the license will need to be renewed.
    this.isAutomaticallySetCoupon = false;

    /* ---------- */
    /*   Shared   */
    /* ---------- */
    this.applyNow = true; // Whether one seat of the license should be activated at purchase time.
    this.autoRenew = true; // Whether the license should be renewed automatically.
    this.buyerId = 0; // The ID of the Socrative user buying the license (from the "socrative_users_socrativeuser" table in the database).
    this.cardNumber = ''; // The credit card number entered by the buyer.
    this.coupon = null; // An object representing a coupon from the "coupons" table in the database.
    this.couponName = ''; // The name of the coupon entered by the buyer.
    this.cvc = ''; // The card verification code entered by the buyer.
    this.expectedTotalPrice = -1; // The total price the buyer can expect to pay (considering seats, years, coupon, and plan).
    this.id = 0; // The ID of a license (from the "licenses" table in the database).
    this.key = ''; // The key of a license (from the "licenses" table in the database).
    this.month = ''; // The credit card expiration month entered by the buyer.
    this.pricePerSeat = 0; // The price per seat the buyer can expect to pay (considering seats, years, coupon, and plan).
    this.percentageDiscount = 0; // The percentage the user has discounted
    this.planId = Constants.PLAN_ID_OTHER; // The plan id associated with this licenese (based on the user's organization type).
    this.plan = []; // The plan from the "plans" table in the database.
    this.seats = 0; // The number of seats entered by the buyer.
    this.tokenId = null; // The Stripe token ID returned by calling Stripe.card.createToken() during purchase.
    this.userId = 0; // The ID of the Socrative user activating the license (from the "socrative_users_socrativeuser" table in the database).
    this.users = []; // Bulk users to be upgraded to PRO via Socrative Admin.
    this.year = 0; // The credit card expiration year entered by the buyer.
    this.years = 0; // The number of years for a license entered by the buyer.
  }

  /* ----------- */
  /*   Getters   */
  /* ----------- */

  get couponSavings() {
    let savings = 0;

    if (this.coupon) {
      savings = `-$${this.coupon.amount / 100}`;
      if (/\.\d$/.test(savings)) {
        savings += '0';
      } else if (!/\./.test(savings)) {
        savings += '.00';
      }
    }

    return savings;
  }

  get pricePerSeatFormatted() {
    return utils.formatPrice(this.pricePerSeat);
  }

  get pricePerSeatPerMonthFormatted() {
    let price = Math.round(this.pricePerSeat / 12);
    return utils.formatPrice(price);
  }

  get pricePerSeatFullFormatted() {
    return utils.formatPrice(this.pricePerSeatFull);
  }

  get pricePerSeatPerMonthFullFormatted() {
    let price = Math.round(this.pricePerSeatFull / 12);
    return utils.formatPrice(price);
  }

  get renewalDateFormatted() {
    return utils.formatDate(this.renewalDate);
  }

  get totalPriceFormatted() {
    return utils.formatPrice(this.expectedTotalPrice);
  }

  get totalBasePriceFormatted() {
    const price = Math.round(this.pricePerSeatFull * this.seats * this.years);
    return utils.formatPrice(price);
  }

  /* --------------- */
  /*   Validations   */
  /* --------------- */

  isValidApplyNow() {
    return this.applyNow === false || this.applyNow === true;
  }

  isValidAutoRenew() {
    return this.autoRenew === false || this.autoRenew === true;
  }

  isValidBuyerId() {
    return this.buyerId > 0;
  }

  isValidCardNumber() {
    return this.cardNumber && this.cardNumber.length > 0;
  }

  isValidCouponName() {
    return this.couponName && this.couponName.length > 0;
  }

  isValidCvc() {
    return this.cvc && this.cvc.length > 0;
  }

  isValidExpectedTotalPrice() {
    return this.expectedTotalPrice !== -1;
  }

  isValidKey() {
    return validations.isValidLicenseKey(this.key);
  }

  isValidNameOnCard() {
    return this.nameOnCard && this.nameOnCard.length > 0;
  }

  isValidPlanId() {
    return validations.isValidDatabaseId(this.planId);
  }

  isValidSeats() {
    return /^\d+$/.test(this.seats) && this.seats > 0;
  }

  isValidTokenId() {
    return this.tokenId;
  }

  isValidUserId() {
    return this.userId > 0;
  }

  isValidUsers() {
    if (!Array.isArray(this.users) || this.users.length === 0) {
      return false;
    }

    for (let user of this.users) {
      if (!user.id) {
        return false;
      }
    }

    return true;
  }

  isValidYears() {
    return this.years > 0 && this.years <= 5;
  }

  /* ------------------ */
  /*   Helper Methods   */
  /* ------------------ */

  setPlanId(orgType) {
    if (orgType === Constants.ORGANIZATION_K12) {
      this.planId = Constants.PLAN_ID_K12;
    } else if (orgType === Constants.ORGANIZATION_HIGHER_ED) {
      this.planId = Constants.PLAN_ID_HIGHER_ED;
    } else if (orgType === Constants.ORGANIZATION_CORP) {
      this.planId = Constants.PLAN_ID_CORPORATE;
    } else {
      this.planId = Constants.PLAN_ID_OTHER;
    }
  }

  calculatePriceData(throwError = false) {
    try {
      let result = calculateFinalPrice(
        this.plan.price,
        this.plan.discounts,
        this.seats,
        this.years,
        this.coupon ? this.coupon.amount : 0
      );

      this.pricePerSeat = result.priceCentsPerSeat;
      this.pricePerSeatFull = this.plan.price;
      this.expectedTotalPrice = result.finalPriceCents;
      this.percentageDiscount = result.percentageDiscount;
    } catch (err) {
      if (throwError) throw err;
    }
  }

  clearCoupon() {
    this.coupon = null;
    this.couponName = '';
    this.couponSuccess = false;
    this.isAutomaticallySetCoupon = false;
  }

  setYearsAndUpdateCoupon(years, couponCallback = () => {}) {
    this.years = years;
    this.coupon = null;

    if (this.couponName) {
      this.fetchCoupon(couponCallback);
    }
  }

  setSeatsAndUpdateCoupon(seats, couponCallback = () => {}) {
    this.seats = seats;
    this.coupon = null;

    if (this.couponName) {
      this.fetchCoupon(couponCallback);
    }
  }

  setRenewalDate(currentExpirationDate = null) {
    let renewalDate = currentExpirationDate || new Date();
    renewalDate.setFullYear(renewalDate.getFullYear() + this.years);
    this.renewalDate = renewalDate;
  }

  canPurchase() {
    let validCardNumber =
      window.location.hostname.toLowerCase() === Constants.PRODUCTION_HOSTNAME
        ? window.Stripe.card.validateCardNumber(this.cardNumber)
        : this.isValidCardNumber();

    this.setError('nameOnCard', !this.isValidNameOnCard());
    this.setError('cardNumber', !validCardNumber);
    this.setError(
      'month',
      !window.Stripe.card.validateExpiry(this.month, this.year)
    );
    this.setError(
      'year',
      !window.Stripe.card.validateExpiry(this.month, this.year)
    );
    this.setError('cvc', !window.Stripe.card.validateCVC(this.cvc));

    return (
      this.isValidNameOnCard() &&
      validCardNumber &&
      window.Stripe.card.validateExpiry(this.month, this.year) &&
      window.Stripe.card.validateCVC(this.cvc)
    );
  }

  setError(input, error, message) {
    this.errors[input].error = error;
    this.errors[input].message = message || '';
  }

  /* ------------------- */
  /*   Client Requests   */
  /* ------------------- */
  fetchPlan(callback = () => {}) {
    request.get({
      url: `${window.teacher_service}/sales/plans`,
      success: (response) => {
        this.plan = response;
        callback(true); // eslint-disable-line
      },
      error: () => {
        callback();
      },
    });
  }

  fetchCoupon(callback) {
    // Clear any existing coupon error
    this.setError('couponName', null);

    if (!this.fetchingCoupon) {
      this.fetchingCoupon = true;
      this.couponName = this.couponName.trim();
      request.get({
        url: `${window.teacher_service}/v3/coupons/name/${this.couponName}?years=${this.years}&seats=${this.seats}`,
        success: (coupon) => {
          this.coupon = coupon;
          this.couponSuccess = true;
          callback(true); // eslint-disable-line
        },
        error: (response) => {
          this.couponSuccess = false;
          if (response.error && response.error === Constants.INVALID_COUPON) {
            this.setError(
              'couponName',
              true,
              translate('This coupon is not valid.')
            );
          } else if (
            response.error &&
            response.error === Constants.EXPIRED_COUPON
          ) {
            this.setError(
              'couponName',
              true,
              translate('This coupon is expired.')
            );
          } else if (
            response.error &&
            response.error === Constants.INVALID_COUPON_TERM
          ) {
            if (response.coupon) {
              if (!this.isAutomaticallySetCoupon) {
                this.isAutomaticallySetCoupon = true;
                this.fetchingCoupon = false;
                this.setYearsAndUpdateCoupon(response.coupon.term, callback);
              } else {
                // Prevent an infinite loop if the coupon has already been set
                this.setError(
                  'couponName',
                  true,
                  translate(
                    'This coupon requires a {0} year subscription term.'.format(
                      response.coupon.term
                    )
                  )
                );
              }
            } else {
              this.setError(
                'couponName',
                true,
                translate('This coupon is not valid.')
              );
            }
          } else {
            this.setError(
              'couponName',
              true,
              translate(
                'There was a problem applying the coupon. Please check your connection and try again.'
              )
            );
          }
          callback();
        },
        complete: () => {
          this.fetchingCoupon = false;
        },
      });
    }
  }

  makePurchase(callback) {
    if (!this.makingPurchase) {
      this.makingPurchase = true;
      window.Stripe.setPublishableKey(window.stripe_key);
      window.Stripe.card.createToken(
        {
          number: this.cardNumber,
          exp_month: this.month,
          exp_year: this.year,
          cvc: this.cvc,
          name: this.nameOnCard,
        },
        (stripeStatus, stripeResponse) => {
          if (stripeResponse && stripeResponse.error) {
            this.makingPurchase = false;
            callback(false, stripeResponse.error.message); // eslint-disable-line
          } else {
            request.post({
              url: `${window.teacher_service}/v3/licenses/`,
              data: {
                applyNow: this.applyNow,
                autoRenew: this.autoRenew,
                buyerId: this.buyerId,
                couponName: this.couponSuccess ? this.couponName : '',
                expectedTotalPrice: this.expectedTotalPrice,
                planId: this.planId,
                seats: this.seats,
                tokenId: stripeResponse.id,
                years: this.years,
              },
              success: (data) => {
                this.key = data.key;
                this.id = data.id;
                callback(true); // eslint-disable-line
              },
              error: (response) => {
                if (response.error === Constants.PRICE_MISMATCH) {
                  this.plan = response.plan;
                  this.calculatePriceData();
                } else if (response.error === Constants.STRIPE_ERROR) {
                  response.error = response.message;
                }
                this.makingPurchase = false;
                callback(false, response.error); // eslint-disable-line
              },
            });
          }
        }
      );
    }
  }

  activateLicense(callback) {
    if (this.activating) {
      return;
    }

    this.activating = true;

    request.post({
      url: `${window.teacher_service}/v3/activations/user`,
      data: { key: this.key },
      success: (response) => {
        this.activating = false;
        callback(true, response.licenseExpiration); // eslint-disable-line
      },
      error: (response) => {
        this.activating = false;

        let message = '';

        switch (response.error) {
          case Constants.INVALID_LICENSE_KEY:
            message = translate('This license key is invalid.');
            break;
          case Constants.LICENSE_MOVED_TO_SUBSCRIPTION:
            message = translate(
              'You cannot apply this license key. Ask a license key owner or admin for a seat on their license.'
            );
            break;
          case Constants.EXPIRED_LICENSE_KEY:
            message = translate('This license key has expired.');
            break;
          case Constants.USERS_ALREADY_LICENSED:
            message = translate(
              'This license key is already active on your account.'
            );
            break;
          case Constants.NOT_ENOUGH_SEATS:
            message = translate('This license key has no seats left.');
            break;
          default:
            message = translate(
              'An unknown error occurred. Please try again later.'
            );
        }

        this.setError('licenseKey', true, message);

        callback(false); // eslint-disable-line
      },
    });
  }

  saveAutoRenew(autoRenew, callback) {
    request.put({
      url: `${window.teacher_service}/v3/licenses/auto-renew`,
      data: {
        autoRenew: autoRenew,
      },
      success: () => callback(true), // eslint-disable-line
      error: () => callback(),
    });
  }
}

module.exports = LicenseModel;
