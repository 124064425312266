module.exports = Object.freeze({
  /* --------------------- */
  /*   Account Constants   */
  /* --------------------- */
  MAX_STRING_LENGTH_32: 32,
  MAX_STRING_LENGTH_64: 64,
  MAX_STRING_LENGTH_128: 128,
  MAX_STRING_LENGTH_255: 255,
  MIN_PASSWORD_LENGTH: 8,
  MAX_PASSWORD_LENGTH: 128,
  ORGANIZATION_CORP: 'CORP',
  ORGANIZATION_HIGHER_ED: 'USHE',
  ORGANIZATION_K12: 'K12',
  ORGANIZATION_OTHER: 'OTHR',
  PRIVACY_URL: 'https://www.socrative.com/privacy.html',
  ROLE_ADMINISTRATOR: 'administrator',
  ROLE_IT_TECHNOLOGY: 'it-technology',
  ROLE_OTHER: 'other',
  ROLE_TEACHER: 'teacher',
  SCHOOL_NOT_LISTED: -1,
  STATUS_COMPLETED: 'completed',
  STATUS_IDLE: 'idle',
  STATUS_SELECTED: 'selected',
  TERMS_URL: 'https://www.socrative.com/terms.html',

  /* ------------------ */
  /*   Activity Types   */
  /* ------------------ */
  QUIZ: 'QZ',
  SPACE_RACE: 'SR',
  NO_REPORT: 'NR', // The type code for QQ-TF and QQ-MC activities, because we don't provide reports for them.
  QQ_SA: '1Q',
  VOTE: '1V',

  /* ---------------- */
  /*   Admin Levels   */
  /* ---------------- */
  EDITOR_USER: 64,
  ADMIN_USER: 128,
  SUPREME_USER: 256,

  /* ---------------- */
  /*  Auth Constants  */
  /* ---------------- */
  JWT_ALGORITHMS: ['HS256'],

  /* -------------------- */
  /*   Banner Constants   */
  /* -------------------- */
  BANNER_ACTION_GO_PRO: 'goProPopup',
  BANNER_ACTION_EXTERNAL_URL: 'externalResource',
  BANNER_ACTION_NONE: 'none',
  BANNER_AUDIENCE_ALL: 0,
  BANNER_AUDIENCE_FREE: 1,
  BANNER_AUDIENCE_PRO: 2,
  BANNER_REMIND_LATER: 64,
  BANNER_READ: 16,
  BANNER_DISMISSED: 256,
  BANNER_SHOW: 1,
  BANNER_STATUS_ACTIVE: 0,
  BANNER_STATUS_HIDDEN: 64,
  BANNER_TIMEOUT: 2000,
  BANNER_TYPE_ERROR: 1,
  BANNER_TYPE_SUCCESS: 2,
  BANNER_INSTITUTION_ALL: 0,
  BANNER_INSTITUTION_K12: 1,
  BANNER_INSTITUTION_USHE: 2,
  BANNER_INSTITUTION_CORP: 4,
  BANNER_INSTITUTION_OTHR: 8,

  /* -------------------- */
  /*   Cache Namespaces   */
  /* -------------------- */
  FOLDERS_NAMESPACE: 'folders',
  USER_NAMESPACE: 'user',
  EXCHANGE_RATE_NAMESPACE: 'exchange',

  /* ------------------ */
  /*   Cache Timeouts   */
  /* ------------------ */
  TEACHER_CACHE_TIMEOUT: 1209600, // 14 days
  EXCHANGE_RATE_TIMEOUT: 1728000,
  REPORT_CACHE_TIMEOUT: 54000, // 15 minutes

  /* ---------- */
  /*   Colors   */
  /* ---------- */
  CLEAR_INPUT_X: '#cbcbcb',
  RENEWAL_COLOR: '#44a5ff',
  PRO_BADGE_COLOR: '#F89E1B',

  /* ---------------- */
  /*   Cookie Names   */
  /* ---------------- */
  AUTH_COOKIE_NAME: 'ua',
  STUDENT_AUTH_COOKIE_NAME: 'sa',
  LANGUAGE_COOKIE_NAME: 'socrative_lang',
  HMAC_COOKIE_NAME: 'hmac',

  /* ---------------- */
  /*  AUTHENTICATION  */
  /* ---------------- */
  HEADER_AUTH_INTEGRATION: 'integration',
  INTEGRATION_AUTH_SESSION_NAME: 'ia',
  INTEGRATION_LANGUAGE_NAME: 'socrative_lang',

  /* --------------- */
  /*   Email Types   */
  /* --------------- */
  ACCOUNTING_EMAIL: 'accounting',
  FORGOT_PASSWORD: 'forgot_password',
  LAST_LOGINS: 'logins',
  MULTI_SEATS_RECEIPT: 'multi_seats_receipt',
  PRO_EXPIRING: 'pro_expiring',
  RECEIPT: 'receipt',
  REGISTRATION_EMAIL: 'registration',
  UPGRADE_PRO_EMAIL: 'upgrade_pro',

  /* ------------------------- */
  /*   Environment Hostnames   */
  /* ------------------------- */
  PRODUCTION_HOSTNAME: 'b.socrative.com',
  UPLOADS_HOSTNAME: 'https://uploads.socrative.com/',

  /* --------------- */
  /*   Error Codes   */
  /* --------------- */
  UNKNOWN_ERROR: -1,
  INVALID_EMAIL: 5,
  INVALID_CHANGE_PASSWORD_TOKEN: 14,
  INVALID_FILE_TYPE: 26,
  INVALID_QUESTION_TYPE: 27,
  INVALID_PASSWORD: 40,
  INVALID_USER_BANNER_STATUS: 58,
  MISSING_LICENSE_DATA: 100,
  INVALID_COUPON: 101,
  EXPIRED_COUPON: 102,
  INVALID_COUPON_TERM: 1011,
  STRIPE_ERROR: 103,
  PRICE_MISMATCH: 104,
  TOO_MANY_LICENSE_KEY_COLLISIONS: 105,
  INVALID_LICENSE_KEY: 106,
  LICENSE_MOVED_TO_SUBSCRIPTION: 1066,
  EXPIRED_LICENSE_KEY: 107,
  USERS_ALREADY_LICENSED: 108,
  ACTIVATION_BLOCKED_BY_POLICY: 109,
  NOT_ENOUGH_SEATS: 110,
  NOT_UNIQUE_NAME: 111,
  NOT_UNIQUE_SEATS: 112,
  BASE_PRICE_SEATS: 113,
  DATABASE_ERROR: 114,
  INVALID_ID: 115,
  COUPON_IN_USE: 116,
  INVALID_FOLDER_DATA: 117,
  INVALID_QUIZ_DATA: 118,
  TOO_MANY_EXCEL_ROWS: 119,
  QUIZ_NAME_TOO_LONG: 120,
  QUESTION_TEXT_MISSING: 121,
  QUIZ_ANSWER_TOO_LONG: 122,
  NOT_ENOUGH_ANSWERS: 123,
  QUIZ_QUESTION_TOO_LONG: 124,
  QUESTIONS_MISSING: 125,
  FOLDER_CANNOT_BE_PURGED: 126,
  FOLDER_NOT_FOUND: 127,
  INVALID_UPDATE_FOLDERS_DATA: 128,
  INVALID_MERGE_QUIZ_DATA: 129,
  TOO_MANY_QUESTIONS_TO_BE_MERGED: 130,
  INVALID_ACCOUNT_DATA: 131,
  QUESTION_TYPE_MISSING: 201,
  AUTH_TOKEN_MISSING: 203,
  EMAIL_MISSING: 204,
  STUDENT_NAME_MISSING: 212,
  PASSWORD_MISSING: 220,
  STUDENT_AUTH_FAILURE: 236,
  INVALID_USER_BANNER_ID: 241,
  QUIZ_NAME_MISSING: 245,
  QUIZ_NOT_FOUND: 400,
  USER_NOT_FOUND: 402,
  STUDENT_NOT_FOUND: 412,
  PERMISSION_DENIED: 600,
  ROOM_ALREADY_EXISTS: 804,
  LOGIN_FAILED: 805,
  ACCOUNT_SUSPENDED: 806,
  ACTIVITY_HAS_ENDED: 807,
  FILE_UPLOAD_ERROR: 809,
  INTERNAL_SERVER_ERROR: 809,
  TEMPORARY_TOKEN_EXPIRED: 814,
  ACTIVITY_RUNNING: 819,
  STRING_IS_TOO_LONG: 821,
  FILE_CORRUPT: 837,
  ROSTER_CONTENT_NOT_AVAILABLE: 837,
  TOO_MANY_QUESTIONS: 839,
  FILE_TOO_LARGE: 840,
  ROSTER_FILE_NOT_IMPORTABLE: 841,
  ROOM_ALREADY_TAKEN: 844, // This and ROOM_ALREADY_EXISTS (804) are effective duplicates of each other.
  STUDENT_ALREADY_LOGGED_IN: 845,
  EMPTY_ROSTER_FILE: 846,
  TOO_MANY_STUDENTS: 848,
  FEATURE_NOT_AVAILABLE: 857,
  ROOM_SHOWBIE_LINKED: 9001,
  QUIZ_UNSUPPORTED_VERSION: 9003,
  ACTIVITY_PAUSED: 9004,

  /* ------------------ */
  /*   Error Messages   */
  /* ------------------ */
  ACTIVATION_BLOCKED_BY_POLICY_MESSAGE:
    'All activations blocked by policy. Please notify a Socrative developer immediately!',
  FILE_UPLOAD_PROBLEM_MESSAGE: 'File upload problem',
  INTERNAL_SERVER_ERROR_MESSAGE: 'Internal server error',
  INVALID_ADMIN_EMAIL_MESSAGE: 'Admin emails must be in the showbie.com domain',
  INVALID_ADMIN_ID_MESSAGE: 'Invalid admin id',
  INVALID_AMOUNT_MESSAGE: 'Invalid amount',
  INVALID_BANNER_ACTION_MESSAGE: 'Invalid banner action',
  INVALID_BANNER_AUDIENCE_MESSAGE: 'Invalid banner audience',
  INVALID_BANNER_INSTITUTION_MESSAGE: 'Invalid banner institution type',
  INVALID_BANNER_CONTENT_MESSAGE: 'Invalid banner content',
  INVALID_BANNER_ENVIRONMENT_MESSAGE: 'Invalid banner environment',
  INVALID_BANNER_ID_MESSAGE: 'Invalid banner id',
  INVALID_BANNER_TITLE_MESSAGE: 'Invalid banner title',
  INVALID_BANNER_URL_MESSAGE: 'Invalid banner URL',
  INVALID_BUYER_MESSAGE: 'Invalid buyer',
  INVALID_EMAIL_ADDRESS_MESSAGE: 'Invalid email address',
  INVALID_EXPIRATION_DATE_MESSAGE: 'Invalid expiration date',
  INVALID_START_DATE_MESSAGE: 'Invalid start date',
  INVALID_QUIZ_ID_MESSAGE: 'Invalid quiz id',
  INVALID_ROLE_MESSAGE: 'Invalid role',
  INVALID_ROOM_MESSAGE: 'Invalid room name',
  INVALID_SEATS_MESSAGE: 'Invalid number of seats',
  INVALID_SEND_EMAILS_MESSAGE: 'Invalid send emails value',
  INVALID_EMAILS_MESSAGE: 'At least one email from the list is not valid',
  INVALID_YEARS_MESSAGE: 'Invalid number of years',
  PERMISSION_DENIED_MESSAGE: 'Permission denied',
  ROOM_NOT_IN_USE_MESSAGE: 'Room not in use',
  TOO_MANY_ROOMS_MESSAGE: 'Too many rooms to merge',
  USERS_ALREADY_PRO_MESSAGE: 'Users already PRO',
  USER_NOT_FOUND_MESSAGE: 'User not found',
  INVALID_COUPON_ALLOW_BULK: 'Invalid allow bulk',
  INVALID_COUPON_ID: 'Invalid coupon id',
  INVALID_PRICE_ID: 'Invalid price id',
  INVALID_COUPON_NAME: 'Invalid coupon name',
  INVALID_PLAN_ID: 'Invalid plan id',
  INVALID_BLING_ID: 'Invalid bling id',
  INVALID_PLAN_NAME: 'Invalid plan name',
  INVALID_PLAN_PRICE: 'Invalid plan price',
  INVALID_PRICE_COMPONENT_NAME: 'Invalid name',
  INVALID_PRICE_COMPONENT_SEATS: 'invalid seats',
  INVALID_PRICE_ID_MESSAGE: 'Invalid price id',
  INVALID_COUPON_EXPIRATION_DATE: 'Invalid coupon expiration date',
  INVALID_START_DATE: 'Invalid start date',
  INVALID_END_DATE: 'Invalid end date',

  /* -------------------- */
  /*   Folder Constants   */
  /* -------------------- */
  NO_FOLDER: 0,
  ROOT_FOLDER: null,
  TRASH: -1,
  QUIZ_FOLDER: 1,
  REPORT_FOLDER: 2,
  MAX_FOLDER_TREE_DEPTH: 6,

  /* ----------------------- */
  /*   Google Login Errors   */
  /* ----------------------- */
  BAD_ACCESS_TOKEN: 'bad_access_token',
  GOOGLE_LOGIN_ERROR: 'google_login_error',
  ZENDESK_SSO_STATE: 'zendesk-sso',

  /* --------------------- */
  /*   HTTP Status Codes   */
  /* --------------------- */
  HTTP_200_OK: 200,
  HTTP_201_CREATED: 201,
  HTTP_204_NO_CONTENT: 204,
  HTTP_302_REDIRECT: 302,
  HTTP_400_BAD_REQUEST: 400,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_403_FORBIDDEN: 403,
  HTTP_404_NOT_FOUND: 404,
  HTTP_429_TOO_MANY_REQUESTS: 429,
  HTTP_500_INTERNAL_SERVER_ERROR: 500,

  /* ---------------------- */
  /*   License Expiration   */
  /* ---------------------- */
  PASSED_EXPIRATION_DAYS: 31,
  RENEWAL_NOTICE_DAYS: 30,

  /* -------------------- */
  /*   License Policies   */
  /* -------------------- */
  ALLOW_POLICY: 1,
  BLOCK_POLICY: 2,

  /* ----------------- */
  /*   License Seats   */
  /* ----------------- */
  MAX_DISPLAY_SEATS: 10000,

  /* ----------------- */
  /*   License Steps   */
  /* ----------------- */
  LICENSE_STEP: 1,
  PAYMENT_STEP: 2,
  COMPLETE_STEP: 3,

  /* ------------------- */
  /*   Messaging Types   */
  /* ------------------- */
  ACTIVITY_CHANGE: 'localActivity',
  HAND_RAISE: 'handraise',
  LOG_STUDENT_OUT: 'student',
  PRESENCE: 'presence',
  STUDENT_FINISHED: 'studentFinished',
  STUDENT_NAME_SET: 'studentNameSet',
  STUDENT_RESPONSE: 'studentResponse',
  STUDENT_STARTED_QUIZ: 'studentStartedQuiz',
  LOGOUT: 'logout',

  /* -------------- */
  /*   Move Tasks   */
  /* -------------- */
  DELETE_TASK: 1,
  MOVE_TASK: 2,
  RESTORE_TASK: 3,

  /* ---------------- */
  /*   Partner Enum   */
  /* ---------------- */
  GOOGLE_PARTNER: 'GO',
  MASTERY_CONNECT_PARTNER: 'MC',

  /* ---------------------- */
  /*   Password Constants   */
  /* ---------------------- */
  PASSWORD_ALGORITHM: 'pbkdf2',
  PASSWORD_DIGEST: 'sha256',
  PASSWORD_ITERATIONS: 12000,
  PASSWORD_KEY_LENGTH: 32,
  PASSWORD_TOKEN_LENGTH: 32,

  /* ------------------ */
  /*   Plan Constants   */
  /* ------------------ */
  PLAN_ID_K12: 1,
  PLAN_ID_HIGHER_ED: 2,
  PLAN_ID_CORPORATE: 3,
  PLAN_ID_OTHER: 4,
  PLAN_NAME_K12: 'K-12',
  PLAN_NAME_HIGHER_ED: 'Higher Ed',
  PLAN_NAME_CORPORATE: 'Corporate',
  PLAN_NAME_OTHER: 'Other',

  /* -------------------- */
  /*   Pubnub Constants   */
  /* -------------------- */
  MAX_PUBNUB_MESSAGE_LENGTH: 4096,

  /* ------------------ */
  /*   Quiz Constants   */
  /* ------------------ */
  EXCEL_MULTIPLE_CHOICE_QUESTION_TYPE: 'Multiple choice',
  EXCEL_OPEN_ENDED_QUESTION_TYPE: 'Open-ended',
  EXCEL_QUIZ_FIRST_QUESTION_ROW: 7,
  EXCEL_QUIZ_MAX_QUESTIONS: 100,
  EXCEL_QUIZ_NAME_CELL: 'B3',
  EXCEL_TYPE: 2,
  FREE_RESPONSE_QUESTION: 'FR',
  LEGACY_ID_LIMIT: 5000000,
  MAX_ANSWER_LENGTH: 10240,
  MAX_QUESTION_LENGTH: 32767, // This is the actual Microsoft limit for characters in a single cell (https://goo.gl/oaYqpD).
  MULTIPLE_CHOICE_QUESTION: 'MC',
  SOC_TYPE: 1,
  MAX_NUMBER_OF_QUESTIONS: 1000,

  /* -------------------------- */
  /*   Registration Constants   */
  /* -------------------------- */
  REGISTRATION_STEP_COMPLETED: 'completed',
  REGISTRATION_STEP_IDLE: 'idle',
  REGISTRATION_STEP_SELECTED: 'selected',

  /* --------------------- */
  /*   Renewal Constants   */
  /* --------------------- */
  PRO_ENDING: 0,
  PRO_ENDED: 1,

  /* --------------------- */
  /*   Reports Constants   */
  /* --------------------- */
  REPORTS_SEARCH_LIMIT: 50,

  /* ------------------ */
  /*   Room Constants   */
  /* ------------------ */
  DEFAULT_ROOM: 1,
  IN_MENU: 2,
  LAST_USED: 8,
  MAX_PRO_ROOMS: 30,
  MAX_ORGANIZATION_ROOMS: 30,
  MAX_COMPLETE_ROOMS: 30,
  MAX_ESSENTIALS_ROOMS: 5,
  MAX_STARTER_ROOMS: 5,
  MAX_ROOM_NAME_LENGTH: 32,
  MAX_SHOWBIE_CLASS_NAME_LENGTH: 128,
  ARCHIVED_ROOM: 128,
  EXPIRED_ROOM: 256,
  NO_ROOM_STATUS_CHANGE: 0b111111111,

  // Socrative uses masked bits to mark status of a room
  ROOM_DEFAULT_BIT: 1,
  ROOM_INMENU_BIT: 2,
  ROOM_ROSTERED_BIT: 4,
  ROOM_LAST_USED_BIT: 8,
  ROOM_HANDRAISE_BIT: 16,
  ROOM_ARCHIVE_BIT: 128,

  /* --------------- */
  /*   Roster File   */
  /* --------------- */
  // Import and Download Column Order
  LAST_FIRST_ID: 1,
  FIRST_LAST_ID: 2,

  // Import and Download Type
  CSV: 1,
  XLSX: 2,

  /* ---------------- */
  /*   Score Export   */
  /*  --------------- */
  // Next Line
  ENTER: 1,
  ENTER_TWICE: 2,
  TAB: 3,
  TAB_TWICE: 4,
  DOWN_ARROW: 5,

  // Speed
  FAST: 50,
  MEDIUM: 150,
  SLOW: 300,
  VERY_SLOW: 500,

  // Export Method
  NUMERIC: 1,
  PERCENTAGE: 2,

  /* ---------------------- */
  /*   Screen Breakpoints   */
  /* ---------------------- */
  BREAKPOINT_PHONE_SMALL: 320,
  BREAKPOINT_PHONE_SMALL_LANDSCAPE: 568,
  BREAKPOINT_PHONE_MEDIUM: 375,
  BREAKPOINT_PHONE_LARGE: 414,
  BREAKPOINT_TABLET: 768,
  BREAKPOINT_DESKTOP_SMALL: 960,
  BREAKPOINT_DESKTOP_LARGE: 1220,

  /* ---------------------------- */
  /*   Stripe Credit Card Types   */
  /* ---------------------------- */
  AMERICAN_EXPRESS: 'American Express',
  DISCOVER: 'Discover',
  MASTERCARD: 'MasterCard',
  VISA: 'Visa',

  /* -------------------- */
  /*   Roster Constants   */
  /* -------------------- */
  MAX_PRO_ROSTERED_STUDENTS: 300,
  MAX_STARTER_ROSTERED_STUDENTS: 50,
  MAX_ESSENTIALS_ROSTERED_STUDENTS: 50,
  MAX_FREE_ROSTERED_STUDENTS: 0,

  /* --------------------- */
  /*   Student Constants   */
  /* --------------------- */
  MAX_STUDENT_ID_LENGTH: 64,
  MAX_STUDENT_ID_INTEGRATION_LENGTH: 64,
  MAX_STUDENT_NAME_LENGTH: 64,
  MAX_STUDENT_EMAIL_LENGTH: 128,

  // Socrative uses masked bits to mark status of a student
  STUDENT_STATUS_DEFAULT_BIT: 0,
  STUDENT_STATUS_LOGGED_IN_BIT: 1,
  STUDENT_STATUS_LOGGED_OUT_BIT: 2,
  STUDENT_STATUS_HANDRAISE_BIT: 4,
  STUDENT_STATUS_ARCHIVED_BIT: 128,

  STUDENT_SYNC_MANUAL: 'MA',
  STUDENT_SYNC_CSV: 'CS',
  STUDENT_SYNC_XLS: 'XL',
  STUDENT_SYNC_INTEGRATION: 'IN',

  /* ----------------------- */
  /*   Teacher Header Tabs   */
  /* ----------------------- */
  LAUNCH_TAB: 'launch',
  QUIZZES_TAB: 'quizzes',
  DISCOVER_TAB: 'discover',
  ROOMS_TAB: 'rooms',
  REPORTS_TAB: 'reports',
  RESULTS_TAB: 'results',
  RESULTS_LIVE: 'results/live',
  HIDE_MENU: 'hide-menu',

  /* ----------------------- */
  /*   Teacher Routes   */
  /* ----------------------- */
  PROFILE_INFO: 'profile/info',
  PROFILE_BILLING: 'profile/billing',
  PROFILE_DEMO: 'profile/demo',
  PRICING: 'pricing',

  /* ----------------------- */
  /*   Jotai Atoms   */
  /* ----------------------- */
  NEW_HEADER: '__new-header',
  ROSTER_MODAL: '__roster-modal',

  /* ------------------- */
  /*   User Type Codes   */
  /* ------------------- */
  FREE_USER: 0,
  PRO_USER: 1,

  /*   Plan Types   */
  /* ------------------- */
  // TODO Stripe M2: Move this to utils
  LEGACY_FREE: 'Free',
  FREE: 'Free',
  PRO: 'Pro',
  STARTER: 'Starter',

  /* ---------------- */
  /*   Worker Tasks   */
  /* ---------------- */
  SEND_EMAIL_TASK: 'email',
  IMAGE_RESIZE_TASK: 'image',

  /* ------------- */
  /*  SERVICE API  */
  /* ------------- */
  RATE_LIMIT_API_MESSAGE: 'Too many requests. Please slow down.',
  RATE_LIMIT_MAX_REQUESTS: 30,
  RATE_LIMIT_WINDOW_MS: 60000, // 1 min
});
